import * as FilePond from 'filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import * as XLSX from 'xlsx';

import 'filepond/dist/filepond.min.css';
import {moduleFormSaveField} from "../functions";

let body = $("body");


//Load the FilePond plugins
FilePond.registerPlugin(FilePondPluginFileValidateType);
FilePond.registerPlugin(FilePondPluginFileValidateSize);

export function voltageOptimisationSpreadsheetUploadField() {
    // Create a multi file upload component
    const pond = FilePond.create({
        multiple: false,
        acceptedFileTypes: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
        allowFileTypeValidation: true,
        labelFileTypeNotAllowed: 'Only Excel files are allowed',
        maxFileSize: '2MB',
        name: 'voltageOptimisationSpreadsheetUpload',
        labelIdle: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"feather feather-upload\"><path d=\"M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4\"></path><polyline points=\"17 8 12 3 7 8\"></polyline><line x1=\"12\" y1=\"3\" x2=\"12\" y2=\"15\"></line></svg><p>Drag & drop your spreadsheet or <span class=\"filepond--label-action\">browse files</span></p>",
        credits: false,

        //On file add
        onaddfile: (error, file) => {
            //Remove error
            $("#voltageOptimisationSpreadsheetUpload .filepond--root").removeClass("error");

            //File file binary
            const fileReader = new FileReader();
            fileReader.onload = (e) => {

                let data = e.target.result;
                let workbook = XLSX.read(data, {type: 'binary'});

                //Get the first worksheet
                let worksheet = workbook.Sheets[workbook.SheetNames[0]];

                //Convert the worksheet to JSON
                let json = XLSX.utils.sheet_to_json(worksheet, {raw: false});

                let daysRecorded = 0;
                let totalConsumption = 0;
                let peakPower = 0;

                //Loop through each row
                json.forEach((row) => {
                    //Set columns
                    let consumptionColumns = ['0:00', '0:30', '1:00', '1:30', '2:00', '2:30', '3:00', '3:30', '4:00', '4:30', '5:00', '5:30', '6:00', '6:30', '7:00', '7:30', '8:00', '8:30', '9:00', '9:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30','14:00', '14:30', '15:00', '15:30','16:00', '16:30', '17:00', '17:30','18:00', '18:30', '19:00', '19:30','20:00', '20:30', '21:00', '21:30','22:00', '22:30', '23:00', '23:30'];
                    let dateColumn = 'Date';
                    let dateFound = false;

                    //Values
                    let date = '';
                    let consumption = 0;

                    //Loop through each column, getting key and value
                    for (const [key, value] of Object.entries(row)) {
                        //Check if column is (first instance of) date
                        if(dateColumn.includes(key) && !dateFound){
                            dateFound = true;

                            //Set date
                            date = value;
                        }

                        //Check if column is (first instance of) consumption
                        if(consumptionColumns.includes(key)){
                            //Remove entry from consumptionColumns array
                            consumptionColumns = removeItemOnce(consumptionColumns, key);

                            //Set consumption
                            consumption += parseFloat(value);

                            //Check if consumption is greater than peakPower
                            if(consumption > peakPower){
                                peakPower = consumption;
                            }
                        }
                    }

                    if(date !== ''){
                        daysRecorded += 1
                        totalConsumption += consumption;
                    }
                })

                let processValues = $(".spreadsheet-input-process-values");

                //Update fields
                processValues.find(".peak-power .box-value").html(peakPower.toLocaleString() + " kW");
                processValues.find(".peak-power .input-value").val(peakPower);
                processValues.find(".annual-consumption .box-value").html(totalConsumption.toLocaleString() + " kW");
                processValues.find(".annual-consumption .input-value").val(totalConsumption);

                //Hide upload field
                $(".spreadsheet-input-field").removeClass("show");

                //Show process values
                processValues.addClass("show");

                //AJAX set values
                moduleFormSaveField("peakPower", peakPower);
                moduleFormSaveField("annualConsumption", totalConsumption);
                moduleFormSaveField("peakAndConsumptionOption", "no");

            };
            fileReader.readAsBinaryString(file.file);
        }
    });

    $("#voltageOptimisationSpreadsheetUpload").html(pond.element);
}

window.voltageOptimisationSpreadsheetUploadField = voltageOptimisationSpreadsheetUploadField;

body.on('click', '#voltageOptimisationChangeSpreadsheet', function(e) {
    e.preventDefault();

    //Hide process values
    $(".spreadsheet-input-process-values").removeClass("show");

    //Show upload field
    $(".spreadsheet-input-field").addClass("show");

    //Delete html of pond file
    $("#voltageOptimisationSpreadsheetUpload").html();

    //Reset values
    moduleFormSaveField("peakPower", null);
    moduleFormSaveField("annualConsumption", null);

    //Recreate pond file
    voltageOptimisationSpreadsheetUploadField();
});

function removeItemOnce(arr, value) {
    let index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}

