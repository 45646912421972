import $ from 'jquery';
import sanitizeHtml from 'sanitize-html';

//Simplebar
import 'simplebar';
import 'simplebar/dist/simplebar.css';

// You will need a ResizeObserver polyfill for browsers that don't support it! (iOS Safari, Edge, ...)
import ResizeObserver from 'resize-observer-polyfill';
window.ResizeObserver = ResizeObserver;

//Setup jQuery
window.jQuery = $;
window.$ = $;

//Setup sanitizeHtml
window.sanitizeHtml = sanitizeHtml;

//Stylesheets
import '../scss/site.scss';

//Scripts
import './functions.js';
import './navbar.js';
import './script.js';
import './survey-input.js';
import './account.js';
import './tooltips.js';

import './cookieconsent.js';

//Pages
import './pages/technologies.js';
import './pages/overview.js';
import './pages/details.js';

//Modules
import './modules/commercialheatpump.js';
import './modules/energymonitoring.js';
import './modules/refrigeration.js';
import './modules/lighting.js';
import './modules/smarttrv.js';
import './modules/smartccontrol.js';
import './modules/voltageoptimisation.js';


//UI
import './ui/dropdown.js';
