/**
 *
 * Refrigeration Module JavaScript
 *
 */

let body = $("body");

//Open input form
body.on('click', '#addRefrigerationUnit', function(e) {
    //Clear inputs
    resetRefrigerationInputForm();

    //Open embed form
    $(".embed-form#refrigerationModuleUnitForm").slideDown("slow");
    $(".listNoData").slideDown("slow");
});

//Save input form
body.on('click', '#saveRefrigerationUnit', function(e) {
    e.preventDefault();
    let refrigerationUnit = refrigerationSerializeInputForm();
    let hasErrors = false;

    //Validate room reference input
    if(refrigerationUnit.roomReference === ""){
        hasErrors = true;

        //Add error state
        $(".embed-form#refrigerationModuleUnitForm #requiredRoomReferenceInput").closest(".input").addClass("error");
    }

    //Validate manufacturer input
    if(refrigerationUnit.manufacturer === ""){
        hasErrors = true;

        //Add error state
        $(".embed-form#refrigerationModuleUnitForm #refrigerationUnitManufacturer").closest(".input").addClass("error");
    }

    //Validate model input
    if(refrigerationUnit.model === ""){
        hasErrors = true;

        //Add error state
        $(".embed-form#refrigerationModuleUnitForm #refrigerationUnitModel").closest(".input").addClass("error");
    }

    //Validate unit type input
    if(refrigerationUnit.unitType === ""){
        hasErrors = true;

        //Add error state
        $(".embed-form#refrigerationModuleUnitForm #refrigerationUnitType").closest(".input").addClass("error");
    }

    //Validate running load amp input
    if(refrigerationUnit.runningLoadAmp === ""){
        hasErrors = true;

        //Add error state
        $(".embed-form#refrigerationModuleUnitForm #refrigerationUnitRunningLoadAmp").closest(".input").addClass("error");
    }

    //Validate quantity input
    if(refrigerationUnit.quantity === "" || refrigerationUnit.quantity === 0){
        hasErrors = true;

        //Add error state
        $(".embed-form#refrigerationModuleUnitForm #refrigerationUnitQuantity").closest(".input").addClass("error");
    }

    if(!hasErrors){
        //Set back-end action
        refrigerationUnit["action"] = "addRefrigerationUnit";

        //Disable save button
        $(this).attr("disabled", true);

        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: {
                data: JSON.stringify(refrigerationUnit)
            },
            success: function (data) {
                $("#refrigerationUnitsList tbody").append(data);
                $(".embed-form#refrigerationModuleUnitForm").slideToggle("slow");
                resetRefrigerationInputForm();
                checkTechnologyData();
            }
        });
    }
});

//Serialize form
function refrigerationSerializeInputForm() {
    let formData = {};

    //Room reference
    formData["roomReference"] = $("#refrigerationModuleUnitForm #requiredRoomReferenceInput").val();

    //Manufacturer
    formData["manufacturer"] = $("#refrigerationModuleUnitForm #refrigerationUnitManufacturer").val();

    //Model
    formData["model"] = $("#refrigerationModuleUnitForm #refrigerationUnitModel").val();

    //Unit type
    formData["unitType"] = $("#refrigerationModuleUnitForm #refrigerationUnitType.dropdown .button .selected").text();

    //Running Load Amp
    formData["runningLoadAmp"] = $("#refrigerationModuleUnitForm #refrigerationUnitRunningLoadAmp").val();

    //Quantity
    formData["quantity"] = $("#refrigerationModuleUnitForm #refrigerationUnitQuantity").val();

    return formData;
}

function resetRefrigerationInputForm() {
    //Remove any masks on the action bar
    $(".actions-bar-container .error-mask").removeClass("active");

    //Reset room reference
    $("#refrigerationModuleUnitForm #requiredRoomReferenceInput").val("").closest(".input").removeClass("error");

    //Reset manufacturer
    $("#refrigerationModuleUnitForm #refrigerationUnitManufacturer").val("").closest(".input").removeClass("error");

    //Reset model
    $("#refrigerationModuleUnitForm #refrigerationUnitModel").val("").closest(".input").removeClass("error");

    //Reset unit tye
    $("#refrigerationModuleUnitForm #refrigerationUnitType .button .selected").text("Select an option").closest(".input").removeClass("error");

    //Reset running load amp
    $("#refrigerationModuleUnitForm #refrigerationUnitRunningLoadAmp").val("").closest(".input").removeClass("error");

    //Reset quantity
    $("#refrigerationModuleUnitForm #refrigerationUnitQuantity").val("").closest(".input").removeClass("error");

    //Enable save button
    $("#refrigerationModuleUnitForm #saveRefrigerationUnit").removeAttr("disabled");

    //Remove empty state
    if($("#refrigerationUnitsList tbody tr").length > 0) {
        $(".listNoData").remove();
    }
}

//Average site voltage field
body.on('input', '.average-site-voltage-field .input', function(e) {
    //Values
    let currentValue = $(this).val();
    const startingValue = $(this).data("starting-value");

    //Save button
    let saveButton = $(".average-site-voltage-field .save-input-button");

    //Max length of 3 characters
    if(currentValue.length > 3){
        const newCurrentValue = currentValue.substring(0, 3);
        $(this).val(newCurrentValue);
        currentValue = newCurrentValue;
    }

    //Check to see if the value has changed
    if(parseInt(currentValue) !== parseInt(startingValue)){
        //Show save button
        saveButton.removeClass("disabled");
    } else {
        //Hide save button
        saveButton.addClass("disabled");
    }
});

//Average site voltage save
body.on('click', '.average-site-voltage-field .save-input-button', function(e) {
    let averageSiteVoltageInput = $('.average-site-voltage-field .input');
    let saveButton = $(this);

    //Disable save button
    saveButton.attr("disabled", true);

    //AJAX save
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "saveRefrigerationAverageSiteVoltage",
                averageSiteVoltage: averageSiteVoltageInput.val()
            })
        },
        success: function (data) {
            //Update starting value in input
            $(".average-site-voltage-field .input").data("starting-value", averageSiteVoltageInput.val());

            //Set save button text
            saveButton.text("Saved!");

            saveButton.delay(1000).fadeOut(250, "linear", function(){
                //Hide save button
                saveButton.addClass("disabled");

                //Remove jquery dade out css
                saveButton.css('display', '');

                //Enable save button
                saveButton.removeAttr("disabled");

                //Set save button text
                saveButton.text("Save");
            });
        }
    });
});

//Remove error state from text inputs
body.on('input', '.embed-form#refrigerationModuleUnitForm .input.error input', function(e){
   $(this).closest('.input.error').removeClass('error');
});

//Remove error state from dropdown inputs
body.on('click', '.embed-form#refrigerationModuleUnitForm .input.error .dropdown .dropdown-content li', function(e){
    $(this).closest('.input.error').removeClass('error');
});

//Delete unit
body.on('click', '.deleteRefrigerationUnit', function(e) {
    let deleteRow = confirm("Are you sure you want to remove this unit?");
    if(deleteRow === true){
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: { data: JSON.stringify({
                    action: "deleteRefrigerationUnit",
                    id: $(this).data("id")
                })},
            success: function(){
                checkTechnologyData();
            }
        });
        $(this).closest("tr").remove();
        if($("#refrigerationUnitsList tbody tr").length < 1){
            $(".panel").append("<style>#refrigerationUnitsList thead tr th:last-child {min-width: 10px;}</style><div class=\"listNoData\"><h3>Add your existing refrigeration units</h3><h4>and we will automatically find you the correct upgrades.</h4><button class=\"action\" id=\"addRefrigerationUnit\">Add unit</button></div>")
        }
    }
});
