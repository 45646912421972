/**
 *
 * Smart AC Control Module JavaScript
 *
 */

let body = $("body");

//Usage time input
body.on('input', '#ac-usage-input-table .time-inputs .input-field input', function(e) {
    //Remove any non numbers or colons
    $(this).val($(this).val().replace(/[^0-9:]/g, ''));

    let value = $(this).val();

    //Get if input was remove or delete of a character
    const isDelete = e.originalEvent.inputType === "deleteContentBackward" || e.originalEvent.inputType === "deleteContentForward";

    //Limit the input to 5 characters
    if (value.length > 5) {
        $(this).val(value.slice(0, 5));
    }

    //Get index of divider
    const dividerIndex = value.indexOf(":");

    //Add divider if user types 2 characters
    if (value.length === 2 && !isDelete && dividerIndex === -1){
        $(this).val(value + ":");
    }
});

body.on('change', '#ac-usage-input-table .time-inputs .input-field input', function(e) {
    $(this).val(format24Hour($(this).val()));

    //Get the row of the table
    const row = $(this).closest("tr");

    //Get inputs in the same row of the table
    const inputs = row.find(".input-field input");

    //Add up the total time
    let startTime;
    let endTime;
    inputs.each(function(){
        //Convert string time to hours
        const time = $(this).val().split(":");
        const hours = parseInt(time[0]);
        const minutes = parseInt(time[1]);

        //Convert to milliseconds
        const date = new Date(0, 0, 0, hours, minutes, 0, 0);

        if($(this).closest(".input-field").hasClass("open-time")){
            startTime = date;
        } else {
            endTime = date;
        }
    });

    //Check to make sure all times are valid Dates
    if(!(startTime instanceof Date) || isNaN(startTime)){
        startTime = new Date(0, 0, 0, 0, 0, 0, 0);
    }
    if(!(endTime instanceof Date) || isNaN(endTime)){
        endTime = new Date(0, 0, 0, 0, 0, 0, 0);
    }

    //Get the difference in milliseconds
    let difference = endTime - startTime;

    //If the difference is negative, add 24 hours
    if(difference < 0){
        difference += 86400000;
    }

    //Convert to 24 format
    const diff = Math.floor(difference / 3600000) + ":" + Math.floor((difference % 3600000) / 60000);

    //Update time difference
    row.find(".hours").text(format24Hour(diff, false));
});

//Toggle input form
body.on('click', '.module-form[data-module=smartACControl] .toggle-smart-ac-add-unit-form', function(e) {
    const form = $(this).closest(".unit-input-container").find(".unit-input-form");

    //Get state of form
    const isOpen = form.hasClass("open");

    if(isOpen){
        //Slide up
        form.slideUp("slow");

        //Close form
        form.removeClass("open");
    } else {
        //Slide down
        form.slideDown("slow");

        //Open form
        form.addClass("open");
    }
});

//Close input form
body.on('click', '.module-form[data-module=smartACControl] .unit-input-container .unit-input-form .cancel-button', function(e) {
    const form = $(this).closest(".unit-input-container").find(".unit-input-form");

    //Slide up
    form.slideUp("slow");

    //Close form
    form.removeClass("open");
});


//Submit unit input form
body.on('submit', '#addSmartACWiredUnitsForm, #addSmartACRemoteUnitsForm', function(e) {
    e.preventDefault();

    let form = $(this);

    //Get if form is for wired or remote units
    const isWired = $(this).attr("id") === "addSmartACWiredUnitsForm";

    let type = "wired";
    let pageNum = 2;
    if(!isWired){
        pageNum = 3;
        type = "remote";
    }

    //Hide errors
    form.find(".errors").hide();

    //Disable submit button
    form.find("button[type=submit]").prop("disabled", true);

    //Serialize form data
    const formData = $(this).serializeArray();

    //Ajax post to add unit
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "addSmartACUnit",
                type: type,
                data: formData
            })
        },
        success: function (data) {
            //Clear form
            form[0].reset();

            //Prefill required fields
            form.find("input[name=estimatedDutyCycle]").val(80);
            form.find("input[name=unoccupiedWorkingHours]").val(0);

            //Enable submit button
            form.find("button[type=submit]").prop("disabled", false);

            //Reset user edited
            form.find("input[name=numberOfControlInterfaces]").data("user-edited", "false");

            const formContainer = $(".module-form[data-module=smartACControl] .unit-input-container .unit-input-form");

            //Close form
            formContainer.slideUp("slow");
            formContainer.removeClass("open");

            const unitList = $(`.module-form[data-module=smartACControl] .page[data-page='${pageNum}'] .inputted-units`);

            if(isWired){
                //Add unit to list
                unitList.append(`<div class="unit-card"><span class="quantity">${sanitizeHtml(data.quantity)} x</span><div class="details"><div class="row"><span class="text larger">${sanitizeHtml(data.identifier)}</span></div><div class="row"><span class="text subtle">Power: ${sanitizeHtml(data.power)} kW</span><span class="text subtle">Duty cycle: ${sanitizeHtml(data.estimatedDutyCycle)}%</span></div><div class="row"><span class="text subtle">Control interfaces: ${sanitizeHtml(data.numberOfControlInterfaces)}</span></div><div class="row"><span class="text subtle">Time unoccupied in work day: ${sanitizeHtml(data.unoccupiedWorkingHours)}%</span></div></div><div class="actions"><button data-id="${sanitizeHtml(data.uuid)}" class="delete-unit"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></button></div></div>`)
            } else {
                //Add unit to list
                unitList.append(`<div class="unit-card"><span class="quantity">${sanitizeHtml(data.quantity)} x</span><div class="details"><div class="row"><span class="text larger">${sanitizeHtml(data.identifier)}</span></div><div class="row"><span class="text subtle">Power: ${sanitizeHtml(data.power)} kW</span></div><div class="row"><span class="text subtle">Duty cycle: ${sanitizeHtml(data.estimatedDutyCycle)}%</span></div><div class="row"><span class="text subtle">Time unoccupied in work day: ${sanitizeHtml(data.unoccupiedWorkingHours)}%</span></div></div><div class="actions"><button data-id="${sanitizeHtml(data.uuid)}" class="delete-unit"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></button></div></div>`)
            }

            //Remove empty state if needed
            unitList.find(".empty-state").addClass("hide").removeClass("error");
        },
        error: function (data) {
            //Enable submit button
            form.find("button[type=submit]").prop("disabled", false);

            //Clear errors
            form.find(".errors .errors-list").html("");

            //Loop through errors
            $.each(data.responseJSON, function (key, value) {
                //Add error to list
                form.find(".errors .errors-list").append("<li class=\"error-item\">" + value + "</li>");
            });

            //Show errors
            form.find(".errors").show();
        }
    });
});

//Delete unit
body.on('click', '.module-form[data-module=smartACControl] .inputted-units .unit-card .delete-unit', function(e) {
    //Confirm delete
    if(!confirm("Are you sure you want to delete this unit?")){
        return;
    }

    //Get unit card
    const unitCard = $(this).closest(".unit-card");

    //Get unit id
    const unitId = $(this).attr("data-id");

    let page = $(this).closest(".page");

    //Get unit type
    let type = "remote";
    if(page.data("page") === 2){
        type = "wired";
    }

    //Remove unit card
    unitCard.remove();

    //Show empty state if needed
    if(page.find(".inputted-units .unit-card").length === 0){
        page.find(".inputted-units .empty-state").removeClass("hide");
    }

    //Ajax post to delete unit
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "removeSmartACUnit",
                type: type,
                id: unitId
            })
        }
    });
});

//System control
body.on('change', 'input[name=systemControl]', function(e) {
    let value = $('input[name=systemControl]:checked').val();

    const page2Button = $(".module-form .form-progress-v2 .stage-button[data-page=2]");
    const page3Button = $(".module-form .form-progress-v2 .stage-button[data-page=3]");

    switch(value){
        case "wired":
            //Enable Wired Units page
            page2Button.removeClass("disabled show-na");
            //Disable Remote Units page
            page3Button.addClass("disabled show-na");
            break;
        case "remote":
            //Disable Wired Units page
            page2Button.addClass("disabled show-na");
            //Enable Remote Units page
            page3Button.removeClass("disabled show-na");
            break;
        case "both":
            //Enable Wired Units page
            page2Button.removeClass("disabled show-na");
            //Enable Remote Units page
            page3Button.removeClass("disabled show-na");
            break;
        default:
            //Disable Wired Units page
            page2Button.addClass("disabled").removeClass("show-na");
            //Disable Remote Units page
            page3Button.addClass("disabled").removeClass("show-na");
            break;
    }
});

//Estimate hours AC left on generator
body.on('input', '.module-form[data-module=smartACControl] .form-pages input[name=wiredUnitHoursUnoccupied], .module-form[data-module=smartACControl] .form-pages input[name=wiredUnitEstimatedNightsACLeftOn], .module-form[data-module=smartACControl] .form-pages input[name=remoteUnitHoursUnoccupied], .module-form[data-module=smartACControl] .form-pages input[name=remoteUnitEstimatedNightsACLeftOn]', function(e) {
    calcAutoFields();
});

//Page change
body.on('click', '.module-form[data-module=smartACControl] .form-actions .button', function(e) {
    calcAutoFields();
});


//Toggle open assumption fields
body.on('click', '.module-form[data-module=smartACControl] .edit-assumptions-button', function(e) {
    const fields = $(this).closest(".unit-input-container").find(".assumption-fields");

    //Get state of form
    const isOpen = fields.hasClass("open");

    if(isOpen){
        //Slide up
        fields.slideUp("fast");

        //Close form
        fields.removeClass("open");

        rotate($(this).find("svg"), 0)
    } else {
        //Slide down
        fields.slideDown("fast");

        //Open form
        fields.addClass("open");

        rotate($(this).find("svg"), 180)

    }
});

//When user edits quantity field
body.on('input', '#addSmartACWiredUnitsForm input[name=quantity], #addSmartACRemoteUnitsForm input[name=quantity]', function(e) {
    //Get form
    const form = $(this).closest("form");

    //Get number of control interfaces input
    const numberOfControlInterfaces = form.find("input[name=numberOfControlInterfaces]");

    //Check if user edited
    if(numberOfControlInterfaces.data("user-edited") !== "true"){
        //Update field with quantity value
        numberOfControlInterfaces.val($(this).val());
    }
});


//Number of control interfaces input edited
body.on('change', '#addSmartACWiredUnitsForm input[name=numberOfControlInterfaces], #addSmartACRemoteUnitsForm input[name=numberOfControlInterfaces]', function(e) {
    //Set user edited
    $(this).data("user-edited", "true");
});

function format24Hour(input, inputted = true){
    let output = input;

    //Remove divider if no other characters
    if (output.length === 1 && output === ":"){
        output = "";
    }

    //Clean up input
    if(output.indexOf(":") !== -1 && (output.indexOf(":") !== 2 || output.length !== 5)){
        //If only one character before divider, add a 0
        if(output.slice(0, output.indexOf(":")).length === 1){
            output = "0" + output;
        }

        //If no characters before divider, add a 00
        if(output.slice(0, output.indexOf(":")).length === 0){
            output = "00" + output;
        }

        //If only one character after divider, add a 0
        if(output.slice(output.indexOf(":") + 1, output.length).length === 1){
            if(inputted){
                output = output + "0";
            } else {
                //Add a 0 after divider
                output = output.slice(0, output.indexOf(":")) + ":0" + output.slice(output.indexOf(":") + 1, output.length);
            }
        }

        //If no characters after divider, add a 00
        if(output.slice(output.indexOf(":") + 1, output.length).length === 0){
            output = output + "00";
        }
    }

    //Limit first 2 digits to 24
    if(parseInt(output.slice(0, output.indexOf(":"))) > 24){
        //Set first two digits to 24
        output = "24" + output.slice(2, output.length);
    }

    //Get text after divider
    const afterDivider = output.slice(output.indexOf(":") + 1, output.length);

    //Limit last 2 digits to 59
    if(parseInt(afterDivider) > 59){
        //Set last two digits to 59
        output = output.slice(0, output.indexOf(":") + 1) + "59";
    }

    //Limit to midnight
    if(parseInt(output.slice(0, output.indexOf(":"))) >= 24){
        output = "24:00";
    }

    //If only one digit, add a 0 to the front and a : to the end
    if(output.length === 1){
        output = "0" + output + ":00";
    }

    return output;
}

function rotate(object, degree) {
    object.css({WebkitTransform: 'rotate(' + degree + 'deg)'});
    object.css({'-moz-transform': 'rotate(' + degree + 'deg)'});
}

function calcAutoFields() {
    const weeksOccupied = parseFloat($(".module-form[data-module=smartACControl] .form-pages input[name=weeksBuildingOccupied]").val());

    //Wired
    let wiredUnitHoursUnoccupied = parseFloat($(".module-form[data-module=smartACControl] .form-pages input[name=wiredUnitHoursUnoccupied]").val());
    let wiredUnitEstimatedNightsACLeftOn = parseFloat($(".module-form[data-module=smartACControl] .form-pages input[name=wiredUnitEstimatedNightsACLeftOn]").val());

    //Make sure inputs are numbers
    if(!isNaN(wiredUnitHoursUnoccupied) && !isNaN(wiredUnitEstimatedNightsACLeftOn)){
        let wiredUnitEstimatedHoursOnElement = $(".module-form[data-module=smartACControl] .form-pages #wiredUnitEstimatedHoursOn");

        //Check to see if rendered estimated hours on are empty
        if(wiredUnitEstimatedHoursOnElement.val() == wiredUnitEstimatedHoursOnElement.data("script-value") && wiredUnitEstimatedHoursOnElement.data("render-value") === ""){
            let wiredUnitEstimatedHoursOn = Math.round(((((wiredUnitHoursUnoccupied * weeksOccupied) / 52) / 7) * wiredUnitEstimatedNightsACLeftOn) * 100) / 100;

            //Render estimated hours on
            if(wiredUnitEstimatedHoursOn === 0){
                wiredUnitEstimatedHoursOnElement.val("");
                wiredUnitEstimatedHoursOnElement.data("script-value", "");
            } else {
                wiredUnitEstimatedHoursOnElement.val(wiredUnitEstimatedHoursOn);
                wiredUnitEstimatedHoursOnElement.data("script-value", wiredUnitEstimatedHoursOn);
            }

            wiredUnitEstimatedHoursOnElement.parent().removeClass("error");
        }
    }


    //Remote
    const remoteUnitHoursUnoccupied = $(".module-form[data-module=smartACControl] .form-pages input[name=remoteUnitHoursUnoccupied]").val();
    const remoteUnitEstimatedNightsACLeftOn = $(".module-form[data-module=smartACControl] .form-pages input[name=remoteUnitEstimatedNightsACLeftOn]").val();

    //Make sure inputs are numbers
    if(!isNaN(remoteUnitHoursUnoccupied) && !isNaN(remoteUnitEstimatedNightsACLeftOn)){
        let remoteUnitEstimatedHoursOnElement = $(".module-form[data-module=smartACControl] .form-pages #remoteUnitEstimatedHoursOn");

        //Check to see if rendered estimated hours on are empty
        if(remoteUnitEstimatedHoursOnElement.val() == remoteUnitEstimatedHoursOnElement.data("script-value") && remoteUnitEstimatedHoursOnElement.data("render-value") === ""){
            let remoteUnitEstimatedHoursOn = Math.round(((((remoteUnitHoursUnoccupied * weeksOccupied) / 52) / 7) * remoteUnitEstimatedNightsACLeftOn) * 100) / 100;

            //Render estimated hours on
            if(remoteUnitEstimatedHoursOn === 0){
                remoteUnitEstimatedHoursOnElement.val("");
                remoteUnitEstimatedHoursOnElement.data("script-value", "");
            } else {
                remoteUnitEstimatedHoursOnElement.val(remoteUnitEstimatedHoursOn);
                remoteUnitEstimatedHoursOnElement.data("script-value", remoteUnitEstimatedHoursOn);
            }

            remoteUnitEstimatedHoursOnElement.parent().removeClass("error");
        }
    }
}